// ** Icon imports
// import HomeIcon from 'mdi-material-ui/Home'
// import PeopleIcon from '@mui/icons-material/People'
// import PersonIcon from '@mui/icons-material/Person'
// import TaskIcon from '@mui/icons-material/Task'
// import LockIcon from '@mui/icons-material/Lock'
// import TopicIcon from '@mui/icons-material/Topic'
// import TranslateIcon from '@mui/icons-material/Translate'
// import HelpIcon from '@mui/icons-material/Help'
// import CommuteIcon from '@mui/icons-material/Commute'
// import AccessibilityRoundedIcon from '@mui/icons-material/AccessibilityRounded'
// import SavingsIcon from '@mui/icons-material/Savings'
// import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded'
// import LocationCityIcon from '@mui/icons-material/LocationCity'
// import CorporateFareIcon from '@mui/icons-material/CorporateFare'
// import GarageIcon from '@mui/icons-material/Garage'
// import StarRoundedIcon from '@mui/icons-material/StarRounded'
// import ContactMailIcon from '@mui/icons-material/ContactMail'
// import PublicIcon from '@mui/icons-material/Public';
// import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
// import AssistantIcon from '@mui/icons-material/Assistant';
import { ReactComponent as UsersIcon } from '../../assets/Users.svg';
import { ReactComponent as PromptLibraryIcon } from '../../assets/PromptLibrary.svg'
import { ReactComponent as FlexiblePriceIcon } from '../../assets/FlexiblePrice.svg'
import { ReactComponent as BillingHistoryIcon } from '../../assets/BillingHistory.svg'
import { ReactComponent as PromptGeneratorIcon } from '../../assets/PromptCategories.svg'
const navigation = () => {
  return [
    {
      title: 'User Profile',
      icon: UsersIcon,
      path: '/userprofile'
    },
    // {
    //   title: 'Prompt Genrator',
    //   icon: PromptGeneratorIcon,
    //   path: '/promptgenrator'
    // },
    {
      title: 'Prompts Library',
      icon: PromptLibraryIcon,
      path: '/promptlist'
    },

    {
      title: 'Flexible Price',
      icon: FlexiblePriceIcon,
      path: '/flexibleprice'
    },

    {
      title: 'Billing Hisotry',
      icon: BillingHistoryIcon,
      path: '/billinghistory'
    },
  ]
}

export default navigation
