import { Box, Button } from '@mui/material'
import IconButton from '@mui/material/IconButton'

// ** Icons Imports
import WeatherNight from 'mdi-material-ui/WeatherNight'
import WeatherSunny from 'mdi-material-ui/WeatherSunny'
import { useNavigate } from 'react-router-dom'

const ModeToggler = props => {
  // ** Props
  const navigate=useNavigate()
  const { settings, saveSettings } = props

  const handleModeChange = mode => {
    saveSettings({ ...settings, mode })
  }

  const handleModeToggle = () => {
    if (settings.mode === 'light') {
      handleModeChange('dark')
    } else {
      handleModeChange('light')
    }
  }

  return (
    <Box>
      <Button variant='contained'
        onClick={()=> navigate('/flexibleprice')}
        sx={{
          bgcolor: '#AAC528',
          fontWeight: 400,
          fontSize: '16px',
          color: 'white',
          padding: '10px 40px',
          borderRadius: '10px',
        }}
      >
      Upgrade Now
      </Button>
      <IconButton color='inherit' aria-haspopup='true' onClick={handleModeToggle}>
        {settings.mode === 'dark' ? <WeatherSunny /> : <WeatherNight />}
      </IconButton>
    </Box>
  )
}

export default ModeToggler
