const DefaultPalette = (mode, skin, themeColor) => {
  // ** Vars
  const lightColor = '58, 53, 65'
  // const darkColor = '231, 227, 252'
  const darkColor = '249, 249, 249'
  const mainColor = mode === 'light' ? lightColor : darkColor

  const primaryGradient = () => {
    if (themeColor === 'primary') {
      return '#AAC528'
    } else if (themeColor === 'secondary') {
      return '#9C9FA4'
    } else if (themeColor === 'success') {
      return '#93DD5C'
    } else if (themeColor === 'error') {
      return '#FF8C90'
    } else if (themeColor === 'warning') {
      return '#FFCF5C'
    } else {
      return '#6ACDFF'
    }
  }

  const defaultBgColor = () => {
    if (skin === 'bordered' && mode === 'light') {
      return '#F9F9F9'
    } else if (skin === 'bordered' && mode === 'dark') {
      return '#0E0E0E'
    } else if (mode === 'light') {
      return '#F5F4F9'
    } else return '#0E0E0E'
  }

  return {
    customColors: {
      dark: darkColor,
      main: mainColor,
      light: lightColor,
      darkBg: '#0E0E0E',
      lightBg: '#F4F5FA',
      primaryGradient: primaryGradient(),
      bodyBg: mode === 'light' ? '#F4F5FA' : '#0E0E0E',
      tableHeaderBg: mode === 'light' ? '#F9FAFC' : '#0e0e0e'
    },
    common: {
      black: '#000',
      white: '#F9F9F9'
    },
    mode: mode,
    primary: {
      light: '#bbd053',
      main: '#AAC528',
      dark: '#AAC528',
      contrastText: '#F9F9F9'
    },
    secondary: {
      light: '#9CE26E',
      main: '#AAC528',
      dark: '#AAC528',
      contrastText: '#F9F9F9'
    },
    success: {
      light: '#6AD01F',
      main: '#56CA00',
      dark: '#4CB200',
      contrastText: '#F9F9F9'
    },
    error: {
      light: '#FF6166',
      main: '#FF4C51',
      dark: '#E04347',
      contrastText: '#F9F9F9'
    },
    warning: {
      light: '#FFCA64',
      main: '#FFB400',
      dark: '#E09E00',
      contrastText: '#F9F9F9'
    },
    info: {
      light: '#32BAFF',
      main: '#16B1FF',
      dark: '#139CE0',
      contrastText: '#F9F9F9'
    },
    grey: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#D5D5D5',
      A200: '#AAAAAA',
      A400: '#616161',
      A700: '#303030'
    },
    neutral: {
      90: '#150425',
      80: '#332640',
      70: '#574766',
      60: '#826C99',
      50: '#B8A3CC',
      40: '#C9B9DA',
      30: '#DBD0E6',
      20: '#F5EFFA',
      10: '#FCFCFD',
    },
    text: {
      primary: `rgba(${mainColor}, 0.87)`,
      secondary: `rgba(${mainColor}, 0.68)`,
      disabled: `rgba(${mainColor}, 0.38)`
    },
    divider: `rgba(${mainColor}, 0.12)`,
    background: {
      paper: mode === 'light' ? '#F9F9F9' : '#202020',
      default: defaultBgColor()
    },
    action: {
      active: `rgba(${mainColor}, 0.54)`,
      hover: `rgba(${mainColor}, 0.04)`,
      selected: `rgba(${mainColor}, 0.08)`,
      disabled: `rgba(${mainColor}, 0.3)`,
      disabledBackground: `rgba(${mainColor}, 0.18)`,
      focus: `rgba(${mainColor}, 0.12)`
    }
  }
}

export default DefaultPalette
