import React, { useState } from 'react';
import {
    FormControl,
    FormHelperText,
    FormLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { axiosInstance } from 'src/network/adapter';
import { ApiEndPoints } from 'src/network/endpoints';
import { LoadingButton } from '@mui/lab';
import Translations from 'src/layouts/components/Translations';
import logo from '../../../assets/Group2.png'
import useCustomTranslation from 'src/@core/hooks/useCustomTranslation';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const schema = yup.object({
    password: yup
        .string()
        .min(8, 'Password must be at least 8 characters')
        .required('Enter Password'),
    confirm_password: yup
        .string("Enter Valid Confirm Password Format")
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .min(8)
        .required("Enter Confirm Password Address"),
});

const passwordResetBtnStyle = {
    marginTop: '20px',
};

function NewPassword({ token }) {
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };
    const handleToggleConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            password: '',
            confirm_password: '',
        },
        resolver: yupResolver(schema),
    });
    const translation = useCustomTranslation();

    const handleSetOtp = async (e) => {
        try {
            const response = await axiosInstance.post(
                ApiEndPoints.AUTH.reset,
                {
                    password: password,
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            if (response.status === 200) {
                navigate('/login');
            } else {
                console.error('Error: Unexpected response status', response.status);
            }
            toast.success(response.data.message);

        } catch (error) {
            toast.error(error.response.data.message)
        }
    };
    return (
        <>
            <form onSubmit={handleSubmit(handleSetOtp)}>
                <img src={logo} alt="logo" style={{ marginBottom: "25px", height: '110px', width: '110px' }} />
                <Typography variant="h3" color='primary' sx={{ my: 2, fontWeight: 700 }}>Reset Password</Typography>
                <FormControl fullWidth sx={{ mb: 4 }}>
                    <FormLabel htmlFor='user Role'>{translation.translate("User Role")}</FormLabel>
                    <Controller
                        name="user-role"
                        control={control}
                        render={({ field: { value, onChange } }) =>
                            <Select
                                onChange={onChange}
                                id='userRole'
                                defaultValue='individual'
                                value={value}
                                displayEmpty
                                IconComponent={KeyboardArrowDownIcon}
                                sx={{
                                    '& .MuiSelect-icon': {
                                        color: '#AAC528',
                                    },
                                }}>
                                <MenuItem value="individual">
                                    <em>Individual</em>
                                </MenuItem>
                                <MenuItem value="client">
                                    <em>Client</em>
                                </MenuItem>
                                <MenuItem value="user">
                                    <em>User</em>
                                </MenuItem>
                            </Select>
                        }
                    />
                    {errors.userRole && (
                        <FormHelperText sx={{ color: 'error.main' }}>
                            <Translations text={`form.user Role.error.${errors.userRole.message}`} />
                        </FormHelperText>
                    )}
                </FormControl>
                <InputLabel>Old Password</InputLabel>
                <FormControl fullWidth>
                    <TextField
                        id="standard-password-input"
                        {...register('password')}
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="current-password"
                        fullWidth
                    // InputProps={{
                    //     endAdornment: (
                    //         <InputAdornment position="end">
                    //             <IconButton onClick={handleTogglePassword} edge="end">
                    //                 {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    //             </IconButton>
                    //         </InputAdornment>
                    //     ),
                    // }}
                    />
                    {/* <small style={{ color: '#FF0000', marginRight: 'auto' }}>
                        {errors.password?.message}
                    </small> */}
                    {errors.password && (
                        <FormHelperText sx={{ color: "error.main" }}>
                            {`${errors.password.message}`}
                        </FormHelperText>
                    )}
                </FormControl>
                <InputLabel sx={{ mt: 2 }}>New Password</InputLabel>
                <FormControl fullWidth >
                    <TextField
                        id="standard-password-input"
                        {...register("confirm password")}
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                        type={showConfirmPassword ? "text" : "password"}
                        autoComplete="current-password"
                        fullWidth
                    // InputProps={{
                    //     endAdornment: (
                    //         <InputAdornment position="end">
                    //             <IconButton
                    //                 onClick={handleToggleConfirmPassword}
                    //                 edge="end"
                    //             >
                    //                 {showConfirmPassword ? (
                    //                     <VisibilityIcon />
                    //                 ) : (
                    //                     <VisibilityOffIcon />
                    //                 )}
                    //             </IconButton>
                    //         </InputAdornment>
                    //     ),
                    // }}
                    />
                    {errors.confirm_password && (
                        <FormHelperText sx={{ color: "error.main" }}>
                            {`${errors.confirm_password.message}`}
                        </FormHelperText>
                    )}
                </FormControl>
                <LoadingButton fullWidth size='large' type='submit' variant='contained' sx={{ ...passwordResetBtnStyle, borderRadius: '10px', color: 'white' }} >
                    <Translations text="Sign In" />
                </LoadingButton>
                <Typography variant='body2' sx={{ textAlign: 'center', mt: 5, color: 'white' }} >
                    Don't have an account? <Link to='/register' style={{ textDecoration: 'none', color: '#AAC528' }}>Sign Up</Link>
                </Typography>
            </form>
        </>
    );
}

export default NewPassword;
