import React, { Suspense } from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import FallbackSpinner from "./@core/components/spinner";
import AuthGuard from "src/@core/components/auth/AuthGuard";
import UserLayout from "./layouts/UserLayout";
import BlankLayout from "./@core/layouts/BlankLayout";
import AclGuard from "./@core/components/auth/AclGuard";
import GuestGuard from "./@core/components/auth/GuestGuard";
import { defaultACLObj } from "./configs/acl";
import AuthLayout from "./layouts/AuthLayout";
import NewPassword from "./views/pages/forgotpassword/newPasswordForm";
// import ResendLinkPage from "./pages/resendlink";


const HomePage = React.lazy(() => import("./pages/home"));
const DashboardPage = React.lazy(() => import("./pages/dashboard"));
const LoginPage = React.lazy(() => import("./pages/login"));
const ResendLinkPage = React.lazy(() => import('./pages/resendlink'))
const RegisterPage = React.lazy(() => import("./pages/login/register"));
const ForgotPassword = React.lazy(() => import("./pages/login/forgotpassword"));
const PromptGenrator = React.lazy(() => import("./views/pages/promptgeneratorsection/promptgenrator"));
const PromptList = React.lazy(() => import('./views/pages/generatedpromptlist'));
const FlexiblePricePage = React.lazy(() => import('./views/pages/pricingsection/flexibleplan&pricing'));
const UserProfilePage = React.lazy(() => import('./views/pages/userprofilesection/userprofile'));
const BillingHistoryPage = React.lazy(() => import('./views/pages/billingsection/billinghistory'));
const UserInformationPage = React.lazy(() => import('./views/pages/userinformationsection'))
const PromptGeneratorEdit = React.lazy(() => import("./views/pages/PromptEdit"));
const Page401 = React.lazy(() => import("./pages/401"));
const Page404 = React.lazy(() => import("./pages/404"));
const VerifyEmail = React.lazy(() => import("./pages/verify-email"));
const CheckEmailPage = React.lazy(() => import('././pages/check-mail'))
const Resetpassword = React.lazy(() => import("./pages/login/resetpassword"));

const Guard = ({ children, authGuard, guestGuard }) => {
  if (guestGuard) {
    return <GuestGuard fallback={<FallbackSpinner />}>{children}</GuestGuard>;
  } else if (!guestGuard && !authGuard) {
    return <>{children}</>;
  } else {
    return <AuthGuard fallback={<FallbackSpinner />}>{children}</AuthGuard>;
  }
};

function App() {
  const aclAbilities = defaultACLObj;

  return (
    <Suspense fallback={<FallbackSpinner />}>
      <AclGuard aclAbilities={aclAbilities}>
      <Routes>
        <Route
          element={
            <BlankLayout>
              <Outlet />
            </BlankLayout>
          }
        >
          <Route path="/401" element={<Page401 />} />
          <Route path="/404" element={<Page404 />} />
          <Route
            element={
              <AuthLayout>
                <Outlet />
              </AuthLayout>
            }
          >
            <Route
              element={
                <Guard guestGuard>
                  <Outlet />
                </Guard>
              }
            >
              <Route path="/register" element={<RegisterPage />}></Route>
              <Route path="/login" element={<LoginPage />}></Route>
              <Route path="/forgot-password" element={<ForgotPassword />}> </Route>
              <Route path="/reset-password" element={<NewPassword />}></Route>
              <Route path="/verify/email" element={<VerifyEmail />}></Route>
              <Route path="/check/email" element={<CheckEmailPage />}></Route>
              <Route path="/resend-link" element={<ResendLinkPage />}></Route>
              <Route path="/reset-password/:token" element={<Resetpassword />}> </Route>
            </Route>
          </Route>
        </Route>
        <Route
          element={
            <UserLayout>
              <Outlet />
            </UserLayout>
          }>
          <Route
            element={<Guard authGuard>
              <Outlet />
            </Guard>}
          >
            <Route path="" element={<HomePage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/promptgenrator" element={<PromptGenrator />} />
            <Route path="/promptlist" element={<PromptList />} />
            <Route path="/flexibleprice" element={<FlexiblePricePage />} />
            <Route path="/userprofile" element={<UserProfilePage />} />
            <Route path="/billinghistory" element={<BillingHistoryPage />} />
            <Route path="/userinformation" element={<UserInformationPage />} />
            <Route path="/promptgenrator-edit/:id" element={<PromptGeneratorEdit />} />

          </Route>
        </Route>
        {/* If no route found redirect it to --> /404 */}
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
      </AclGuard>
    </Suspense>
  );
}

export default App;
