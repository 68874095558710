const Typography = theme => {
  return {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#F9F9F9 !important"
        },
        gutterBottom: {
          marginBottom: theme.spacing(2)
        }
      }
    }
  }
}

export default Typography
